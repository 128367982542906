import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import VisibilityIcon from "@material-ui/icons/Visibility";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import CachedIcon from "@material-ui/icons/Cached";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";

import oapOverviewFlowchart from "assets/img/sections/oap-overview-flowchart.png";

const useStyles = makeStyles(featuresStyle);

export default function SectionFeatures({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
        <div className={classes.features3}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <div className={classes.chartContainer}>
                <img src={oapOverviewFlowchart} alt="..." />
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <h2 className={classes.title}>
                Real-time insights and full operational control of your entire
                creative workflow
              </h2>
              <InfoArea
                className={classes.infoArea}
                icon={VisibilityIcon}
                title="Visibility"
                description="Track your entire project workflow, from request through creative to delivery."
                iconColor="info"
              />
              <InfoArea
                className={classes.infoArea}
                icon={CachedIcon}
                title="Standardize and Streamline"
                description="Standardize processes across departments &amp; facilities and streamline communication between creative departments and operations."
                iconColor="primary"
              />
              <InfoArea
                className={classes.infoArea}
                icon={MergeTypeIcon}
                title="Consolidate"
                description="Eliminate problems associated with backing up work, sharing scripts, creating and interpreting file naming conventions, and distributing updated versions of work orders and schedules."
                iconColor="danger"
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
